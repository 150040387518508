import api from '../api';

export default {
  list(params) {
    return api().get('/api/seasons', { params });
  },
  count(params) {
    return api().get('/api/seasons/count', { params });
  },
  create(params) {
    return api().post('/api/seasons/new', params);
  },
  delete(params) {
    return api().get('/api/seasons/delete', { params });
  },
};
