import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/stores/invoices', { signal, params });
  },
  get(params) {
    return api().get('/api/stores/invoices/details', { params });
  },
  create(params) {
    return api().post('/api/stores/invoices/new', params);
  },
  delete(params) {
    return api().get('/api/stores/invoices/delete', { params });
  },
  PDF(params) {
    return api().get('/api/stores/invoices/pdf', { params, responseType: 'blob' });
  },
};
