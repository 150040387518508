import api from '../api';

export default {
  list(params) {
    return api().get('/api/product-options', { params });
  },
  count(params) {
    return api().get('/api/product-options/count', { params });
  },
  create(params) {
    return api().post('/api/product-options/new', params);
  },
  delete(params) {
    return api().get('/api/product-options/delete', { params });
  },

  valueList(params) {
    return api().get('/api/product-options/values', { params });
  },
  createValue(params) {
    return api().post('/api/product-options/values/new', params);
  },
  deleteValue(params) {
    return api().get('/api/product-options/values/delete', { params });
  },
};
