import api from '../api';

export default {
  list(params) {
    return api().get('/api/vat-types', { params });
  },
  count(params) {
    return api().get('/api/vat-types/count', { params });
  },
  create(params) {
    return api().post('/api/vat-types/new', params);
  },
  update(params) {
    return api().post('/api/vat-types/edit', params);
  },
  delete(params) {
    return api().get('/api/vat-types/delete', { params });
  },
};
