import api from '../api';

export default {
  list(params) {
    return api().get('/api/appointments/types', { params });
  },
  create(params) {
    return api().post('/api/appointments/types/new', params);
  },
  update(params) {
    return api().post('/api/appointments/types/edit', params);
  },
  sort(params) {
    return api().post('/api/appointments/types/sort', params);
  },
  delete(params) {
    return api().get('/api/appointments/types/delete', { params });
  },
};
