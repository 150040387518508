import dayjs from "../extensions/dayjs";
import store from "../store";

export default {

  validRequired(text) {
    if (typeof text === 'undefined' || text === null || text === '' || text === false) return false;
    const re = /\S{1,}/;
    return re.test(text);
  },
  validEmail(email) {
    const re = /(.+)@(.+){2,}\.(.+){2,}/;
    //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  },
  validPassword(password) {
    const re = /^(?=.*[0-9])(?=.*[!#*$@()+.-:?_|])[a-zA-Z0-9!#*$@()+.-:?_|]{6,}$/;
    return re.test(password);
  },
  validPasswordConfirmation(password, confPassword) {
    const re = /^(?=.*[0-9])(?=.*[!#*$@()+.-:?_|])[a-zA-Z0-9!#*$@()+.-:?_|]{6,}$/;
    return re.test(password) && password === confPassword;
  },
  validCheck(check) {
    return check;
  },
  validFiscalCode(cfins) {
    const cf = cfins.toUpperCase();
    const cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    if (!cfReg.test(cf)) {
      return false;
    }

    const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    let s = 0;
    for (let i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (let i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0)) return false;

    return true;
  },
  validVatNumber(pi) {
    if (pi === '') return false;
    else if (!/^[0-9]{11}$/.test(pi)) return false;
    else {
      const validi = "0123456789";
      for (let i = 0; i < 11; i++) {
        if (validi.indexOf(pi.charAt(i)) === -1) return false;
      }

      let s = 0;
      for (let i = 0; i <= 9; i += 2) {
        s += pi.charCodeAt(i) - '0'.charCodeAt(0);
      }
      for (let i = 1; i <= 9; i += 2) {
        var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) c = c - 9;
        s += c;
      }
      const controllo = (10 - s % 10) % 10;
      if (controllo !== pi.charCodeAt(10) - '0'.charCodeAt(0)) return false;
      else return true;
    }
  },
  validOnlyNumber(text) {
    if (text === null || text === '') return false;
    const re = /^\d+$/;
    return re.test(text);
  },
  validDomain(domain) {
    let filter = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    return filter.test(domain);
  },
  validUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  },

  validateForm(fields, errors) {
    let isDisabled = false;
    fields.forEach((index) => {
      if (typeof errors[index] === "undefined" || errors[index]) {
        isDisabled = true;
      }
    });
    return isDisabled;
  },

  formatCompactNumber(number) {
    if (typeof number === "undefined") return 0
    const formatter = Intl.NumberFormat("en", {notation: "compact"});
    return formatter.format(number);
  },
  readableBytes(kb) {
    const bytes = kb * 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + '' + sizes[i];
  },
  clearSlug(s, opt) {
    s = String(s);
    opt = Object(opt);

    var defaults = {
      'delimiter': '-',
      'limit': undefined,
      'lowercase': true,
      'clear_end': true,
      'point': false,
      'full_url': false,
      'replacements': {},
      'transliterate': true
    };

    // Merge options
    for (let k in defaults) {
      if (!Object.prototype.hasOwnProperty.call(opt,k)) {
        opt[k] = defaults[k];
      }
    }

    var char_map = {
      // Latin
      'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
      'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
      'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
      'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
      'ß': 'ss',
      'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
      'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
      'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
      'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
      'ÿ': 'y',

      // Latin symbols
      '©': '(c)',

      // Greek
      'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
      'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
      'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
      'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
      'Ϋ': 'Y',
      'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
      'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
      'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
      'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
      'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

      // Turkish
      'Ş': 'S', 'İ': 'I', 'Ğ': 'G',
      'ş': 's', 'ı': 'i', 'ğ': 'g',

      // Russian
      'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
      'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
      'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
      'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
      'Я': 'Ya',
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
      'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
      'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
      'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
      'я': 'ya',

      // Ukrainian
      'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
      'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

      // Czech
      'Č': 'C', 'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R', 'Š': 'S', 'Ť': 'T', 'Ů': 'U',
      'Ž': 'Z',
      'č': 'c', 'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'š': 's', 'ť': 't', 'ů': 'u',
      'ž': 'z',

      // Polish
      'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ś': 'S', 'Ź': 'Z',
      'Ż': 'Z',
      'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ś': 's', 'ź': 'z',
      'ż': 'z',

      // Latvian
      'Ā': 'A', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N',
      'Ū': 'u',
      'ā': 'a', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
      'ū': 'u'
    };

    // Make custom replacements
    for (let k in opt.replacements) {
      s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
    }

    // Transliterate characters to ASCII
    if (opt.transliterate) {
      for (let k in char_map) {
        s = s.replace(RegExp(k, 'g'), char_map[k]);
      }
    }

    // Replace non-alphanumeric characters with our delimiter
    var reg = opt.point ? '[^a-z0-9_.#?]+' : '[^a-z0-9_#?]+';
    if (opt.full_url) reg = '[^a-z0-9._/:#?]+';
    var alnum = RegExp(reg, 'ig');
    s = s.replace(alnum, opt.delimiter);

    // Remove duplicate delimiters
    s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

    // Truncate slug to max. characters
    s = s.substring(0, opt.limit);

    if (opt.clear_end) {
      // Remove delimiter from start and ends
      s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');
    } else {
      // Remove delimiter from start
      s = s.replace(RegExp('(^' + opt.delimiter + ')', 'g'), '');
    }

    return opt.lowercase ? s.toLowerCase() : s;
  },
  randomNumber(min = 0, max = 50) {
    return Math.floor(Math.random() * (max - min) + min);
  },
  generateId() {
    const randomId = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
    return randomId;
  },
  copyText(message) {
    navigator.clipboard.writeText(message).then(() => true, () => false);
  },
  formatDate(value = null, format = 'DD/MM/YYYY', timezone = null) {
    if(timezone === null) timezone = store.getters.timezone;
    const date = value ? dayjs(String(value)) : dayjs();
    const dateTz = timezone ? date.tz(timezone) : date.isUTC() || !format.includes('H') || (value && typeof value === "string" && !value.includes('T')) ? date : date.utc();
    return dateTz.format(format);
  },
  formatTime(min) {
    const time = dayjs.utc(dayjs.duration(min, "minutes").asMilliseconds());
    const minutes = time.minute();
    const hours = time.hour();
    const hourFormatStr = hours === 1 ? 'ora' : 'ore';
    const minuteFormatStr = minutes === 1 ? 'minuto' : 'minuti';
    if (!time.minute()) return time.format(`h [${hourFormatStr}]`);
    if (!time.hour()) return time.format(`m [${minuteFormatStr}]`);
    return time.format(`h [${hourFormatStr}] [e] m [${minuteFormatStr}]`);
  },
  timeAgo(date, maxDays, format = 'DD/MM/YYYY') {
    const momentDate = dayjs(date);
    if (maxDays && dayjs().diff(momentDate, 'day') > (maxDays - 1)) return momentDate.format(format)
    return momentDate.fromNow()
  },
  recursiveMap(obj, callback) {
    if (typeof obj !== 'object') return obj;
    // Use Object.entries() to get an array of the object's key-value pairs
    Object.entries(obj)
      ?.forEach(([key, val]) => {
        // If the value is an object, recursively call the function with that object as the new argument
        if (typeof val === 'object' && val !== null) {
          obj[key] = this.recursiveMap(val, callback);
        }
          // If the value is not an object, call the callback function with that value as the argument,
        // and assign the result of the callback to the property
        else {
          obj[key] = callback(val);
        }
      });
    // Return the mapped object
    return obj;
  }
}
