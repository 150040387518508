import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/inventories', { signal, params });
  },
  create(params) {
    return api().post('/api/inventories', params);
  },
  open(params) {
    return api().get('/api/inventories/open', { params });
  },
  get(id, params) {
    return api().get(`/api/inventories/${id}`, { params });
  },
  products(id, params, signal) {
    return api().get(`/api/inventories/${id}/products`, { signal, params });
  },
  add(id, params) {
    return api().post(`/api/inventories/${id}`, params);
  },
  changeQuantity(id, params) {
    return api().post(`/api/inventories/${id}/quantity`, params);
  },
  delete(id, params) {
    return api().get(`/api/inventories/${id}/delete`, { params });
  },
  close(id, params) {
    return api().post(`/api/inventories/${id}/close`, params);
  },
};
