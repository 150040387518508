import api from '../api';

export default {
  contractStatus(params) {
    return api().get('/api/statistics/contract-status', { params });
  },
  contractDepartments(params) {
    return api().get('/api/statistics/contract-departments', { params });
  },
};
