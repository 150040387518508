import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/suppliers', { signal, params });
  },
  get(params) {
    return api().get('/api/suppliers/get', { params });
  },
  create(params) {
    return api().post('/api/suppliers/new', params);
  },
  edit(params) {
    return api().post('/api/suppliers/edit', params);
  },
  delete(params) {
    return api().get('/api/suppliers/delete', { params });
  },
};
