<template>
    <div class="wizard d-flex flex-row-fluid flex-column flex-column-fluid text-center pt-4 pb-8 py-lg-20">
      <div class="text-center">
        <div class="wizard-body">
          <div class="container">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'WizardLayout',
};
</script>

<style scoped lang="scss">
.wizard {
  position: relative;
  background: var(--bs-body-bg);
  z-index: 10;

  .btn-exit {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }
}
</style>
