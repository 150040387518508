import api from '../api';

export default {
  supplierCollectionStats(params) {
    return api().get('/api/widget/supplier-collection-stats', { params });
  },
  supplierSales(params) {
    return api().get('/api/widget/supplier-sales', { params });
  },
  supplierBrands(params) {
    return api().get('/api/widget/supplier-brands', { params });
  },
};
