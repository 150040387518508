import api from '../api';

export default {
  reset(params) {
    return api().post('/api/import/reset', params);
  },
  setContractsStatus(params) {
    return api().post('/api/import/contracts-status', params);
  },
  setProductVariants(params) {
    return api().post('/api/import/product-variants', params);
  },
  setLastAppointment(params) {
    return api().post('/api/import/set-last-appointment', params);
  },
  setAppointmentOperator(params) {
    return api().post('/api/import/set-appointment-operator', params);
  },
  store(params) {
    return api().post('/api/import/store', params);
  },
  employees(params) {
    return api().post('/api/import/employees', params);
  },
  contracts(params) {
    return api().post('/api/import/contracts', params);
  },
  contractProducts(params) {
    return api().post('/api/import/contract-products', params);
  },
  contractPayments(params) {
    return api().post('/api/import/contract-payments', params);
  },
  contractPhotos(params) {
    return api().post('/api/import/contract-photos', params);
  },
  interventions(params) {
    return api().post('/api/import/interventions', params);
  },
  appointments(params) {
    return api().post('/api/import/appointments', params);
  },
  orders(params) {
    return api().post('/api/import/orders', params);
  },
  ddts(params) {
    return api().post('/api/import/ddts', params);
  },
  invoices(params) {
    return api().post('/api/import/invoices', params);
  },
  invoicePayments(params) {
    return api().post('/api/import/invoice-payments', params);
  },
  invoiceClientMeasures(params) {
    return api().post('/api/import/client-measures', params);
  },
};
