import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/brands', { signal, params });
  },
  count(params) {
    return api().get('/api/brands/count', { params });
  },
  create(params) {
    return api().post('/api/brands/new', params);
  },
  delete(params) {
    return api().get('/api/brands/delete', { params });
  },
};
