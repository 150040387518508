export default {
    it: {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        compactShort: {
            notation: 'compact', compactDisplay: 'short'
        },
        compactLong: {
            notation: 'compact', compactDisplay: 'long'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    es: {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        compactShort: {
            notation: 'compact', compactDisplay: 'short'
        },
        compactLong: {
            notation: 'compact', compactDisplay: 'long'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    en: {
        currency: {
            style: 'currency', currency: 'GBP', notation: 'standard'
        },
        compactShort: {
            notation: 'compact', compactDisplay: 'short'
        },
        compactLong: {
            notation: 'compact', compactDisplay: 'long'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
}