import api from '../api';

export default {
  list(params) {
    return api().get('/api/tags', { params });
  },
  count(params) {
    return api().get('/api/tags/count', { params });
  },
  create(params) {
    return api().post('/api/tags/new', params);
  },
  delete(params) {
    return api().get('/api/tags/delete', { params });
  },
};
