import store from '@/store';

export default (to, from, next) => {
  if (store.getters.verified) {
    next();
  } else if (store.getters.authenticated) {
    next({ name: 'verify' });
  } else {
    next({ name: 'login' });
  }
};
