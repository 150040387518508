import axios from 'axios';
import { useRoute, useRouter } from 'vue-router'
//import { setupCache } from 'axios-cache-interceptor';

const instance = (contentType) => {
  const ct = (typeof contentType === 'undefined') ? 'application/json' : contentType;
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      'Content-Type': ct,
    },
  });

  const router = useRouter()
  const route = useRoute()

  axiosInstance.interceptors.request.use((config) => {
    const store = JSON.parse(sessionStorage.getItem('store'));
    const storeId = store?.id;
    if(storeId) config.headers.StoreId = storeId;
    return config;
  });


  axiosInstance.interceptors.response.use((response) => response, (error) => {
    switch (error.response?.status) {
      case 401: // Not logged in
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        sessionStorage.removeItem('user');
        window.location.reload();
        break;
      case 404:
        router.push({
          name: 'PlatformNotFound',
          // preserve current path and remove the first char to avoid the target URL starting with `//`
          params: { pathMatch: route.path.substring(1).split('/') },
          // preserve existing query and hash if any
          query: route.query,
          hash: route.hash,
        })
        break;
      case 500:
        alert('Oops, something went wrong!  The team have been notified.');
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
    }
  });

  return axiosInstance;
  //return setupCache(axiosInstance);
};

export default instance;
