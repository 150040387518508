import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/contracts/ceremonies', { signal, params });
  },
  create(params) {
    return api().post('/api/contracts/ceremonies', params);
  },
  update(params) {
    return api().post('/api/contracts/ceremonies/edit', params);
  },
  delete(params) {
    return api().get('/api/contracts/ceremonies/delete', { params });
  },
  related(params, signal = null) {
    return api().get('/api/contracts/ceremonies/related', { signal, params });
  },
  checkProductInOtherGuests(params, signal = null) {
    return api().get('/api/contracts/ceremonies/check-product-in-other-guests', { signal, params });
  },
};
