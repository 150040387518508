import repository from '@/api/repository';
export default {
  namespaced: true,

  state: () => ({
    contract: null,
  }),
  getters: {
    contract: (state) => state.contract,
  },
  mutations: {
    SET_CONTRACT(state, value) {
      state.contract = value;
    },
  },
  actions: {
    async get({commit}, id) {
      try {
        return await repository.contract.get({id})
          .then((res) => {
            if(res) {
              const data = res.data;
              commit('SET_CONTRACT', data.data);
              return Promise.resolve(data.data);
            }
            return Promise.reject(null);
          });
      } catch (error) {
        return error;
      }
    },

  },
};
