import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/stores/internal-ddts', { signal, params });
  },
  get(params) {
    return api().get('/api/stores/internal-ddts/details', { params });
  },
  create(params) {
    return api().post('/api/stores/internal-ddts/new', params);
  },
  delete(params) {
    return api().get('/api/stores/internal-ddts/delete', { params });
  },
  PDF(params) {
    return api().get('/api/stores/internal-ddts/pdf', { params, responseType: 'blob' });
  },
  listReceived(params, signal = null) {
    return api().get('/api/stores/internal-ddts/received', { signal, params });
  },
  import(params) {
    return api().post('/api/stores/internal-ddts/import', params);
  },
};
