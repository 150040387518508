import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/contracts/interventions', { signal, params });
  },
  get(params) {
    return api().get('/api/contracts/interventions/details', { params });
  },
  count(params) {
    return api().get('/api/contracts/interventions/count', { params });
  },
  listOfContracts(params, signal = null) {
    return api().get('/api/contracts/interventions/contracts', { signal, params });
  },
  countOfContracts(params) {
    return api().get('/api/contracts/interventions/contracts/count', { params });
  },
  create(params) {
    return api().post('/api/contracts/interventions/new', params);
  },
  edit(params) {
    return api().post('/api/contracts/interventions/edit', params);
  },
  doneAll(params) {
    return api().post('/api/contracts/interventions/done-all', params);
  },
  doneTask(params) {
    return api().post('/api/contracts/interventions/done', params);
  },
  delete(params) {
    return api().get('/api/contracts/interventions/delete', { params });
  },
  listPDF(params) {
    return api().get('/api/contracts/interventions/list-pdf', { params, responseType: 'blob' });
  },
};
