import api from '../api';

function getErrorResponse(error, reference = ''){
  return {
    success: false,
    data: null,
    message: error.message,
    errors: (error.response?.data ? error.response.data : ''),
    reference
  };
}
function getErrorResponsePromise(error, reference = ''){
  const e =  {
    success: false,
    data: null,
    message: error.message,
    errors: (error.response?.data ? error.response.data : ''),
    reference
  };
  return Promise.reject(e);
}

export default {
  list(params) {
    return api().get('/api/employees', { params })
      .catch((error) => getErrorResponse(error));
  },
  get(params) {
    return api().get('/api/employees/details', { params })
      .catch((error) => getErrorResponse(error));
  },
  search(params) {
    return api().get('/api/employees/search', { params })
      .catch((error) => getErrorResponse(error));
  },
  count(params) {
    return api().get('/api/employees/count', { params })
      .catch((error) => getErrorResponse(error));
  },
  invite(params) {
    return api().post('/api/employees/invite', params)
      .catch((error) => getErrorResponse(error));
  },
  set(params) {
    return api().post('/api/employees/set', params)
      .catch((error) => getErrorResponse(error));
  },
  delete(params) {
    return api().get('/api/employees/delete', { params })
      .catch((error) => getErrorResponse(error));
  },
  setAvailabilities(params) {
    return api().post('/api/employees/availabilities', params)
      .catch((error) => getErrorResponse(error));
  },
  getAllAvailabilities(params) {
    return api().get('/api/employees/availabilities/all', { params })
      .catch((error) => getErrorResponse(error));
  },
  getHolidays(params) {
    return api().get('/api/employees/holidays', { params })
      .catch((error) => getErrorResponsePromise(error));
  },
  setHolidays(params) {
    return api().post('/api/employees/holidays', params)
      .catch((error) => getErrorResponsePromise(error));
  },
  deleteHoliday(params) {
    return api().get('/api/employees/holidays/delete', { params })
      .catch((error) => getErrorResponsePromise(error));
  },
};
