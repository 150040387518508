import repository from '@/api/repository';
export default {
  namespaced: true,

  state: () => ({
    product: null,
    filters: {},
    filtersActive: [],
  }),
  getters: {
    getProduct: (state) => state.product,
    getFilters: (state) => state.filters,
    getFiltersActive: (state) => state.filtersActive,
  },
  mutations: {
    SET_PRODUCT(state, value) {
      state.product = value;
    },

    /** FILTERS **/
    SET_FILTERS(state, value) {
      state.filters = value;
    },
    SET_FILTERS_ACTIVE(state, value) {
      state.filtersActive = value;
    },
  },
  actions: {
    async get({commit}, id) {
      try {
        return await repository.product.get({id,})
          .then(({ data }) => {
            commit('SET_PRODUCT', data.data);
            return Promise.resolve(data.data);
          });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /** FILTERS **/
    async setFilters({ commit }, value) {
      commit('SET_FILTERS', value);
    },
    async setFiltersActive({ commit }, value) {
      commit('SET_FILTERS_ACTIVE', value);
    },

  },
};
