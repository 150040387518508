import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/chat/list', { signal, params });
  },
  toRead(params, signal = null) {
    return api().get('/api/chat/to-read', { signal, params });
  },
  messages(params, signal = null) {
    return api().get('/api/chat/messages', { signal, params });
  },
  sendReply(params) {
    return api().post('/api/chat/send-reply', params);
  },
  delete(params) {
    return api().get('/api/chat/delete', { params });
  },
};
