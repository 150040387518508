import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/stores/direct-orders', { signal, params });
  },
  get(params) {
    return api().get('/api/stores/direct-orders/details', { params });
  },
  create(params) {
    return api().post('/api/stores/direct-orders/new', params);
  },
  delete(params) {
    return api().get('/api/stores/direct-orders/delete', { params });
  },
  products(params) {
    return api().get('/api/stores/direct-orders/products', { params });
  },
  receiptPrint(params) {
    return api().post('/api/stores/direct-orders/receipt-print', params);
  },
};
