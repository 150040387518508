import guest from './rules/guest';
import user from './rules/user';
import verified from './rules/verified';
import notVerified from './rules/notVerified';
import isAdmin from './rules/isAdmin';
import hasPermissions from './rules/hasPermissions';

export default {
  guest,
  user,
  verified,
  notVerified,
  isAdmin,
  hasPermissions,
};
