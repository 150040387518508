const dayjs = require('dayjs')
require('dayjs/locale/it')
dayjs.locale('it')

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("utc")

const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default dayjs;
