import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/contracts', {signal, params});
  },
  statsList(params, signal = null) {
    return api().get('/api/contracts/statsList', {signal, params});
  },
  totalSold(params) {
    return api().get('/api/contracts/totalSold', {params});
  },
  remainderToReceive(params) {
    return api().get('/api/contracts/remainderToReceive', {params});
  },
  stillOpen(params) {
    return api().get('/api/contracts/stillOpen', {params});
  },
  count(params) {
    return api().get('/api/contracts/count', {params});
  },
  get(params) {
    return api().get('/api/contracts/details', {params});
  },
  getByNumber(params) {
    return api().get('/api/contracts/details-by-number', {params});
  },
  create(params) {
    return api().post('/api/contracts/new', params);
  },
  delete(params) {
    return api().get('/api/contracts/delete', {params});
  },
  options(params) {
    return api().get('/api/contracts/options', {params});
  },
  payments(params) {
    return api().get('/api/contracts/payments', {params});
  },
  createPayment(params) {
    return api().post('/api/contracts/payments/new', params);
  },
  setOperator(params) {
    return api().post('/api/contracts/operator', params);
  },
  setCeremony(params) {
    return api().post('/api/contracts/ceremony', params);
  },
  paymentPayed(params) {
    return api().post('/api/contracts/payments/payed', params);
  },
  deletePayment(params) {
    return api().get('/api/contracts/payments/delete', {params});
  },
  receiptPrint(params) {
    return api().post('/api/contracts/payments/receipt-print', params);
  },
  addDiscount(params) {
    return api().post('/api/contracts/discount', params);
  },
  appointments(params) {
    return api().get('/api/contracts/appointments', {params});
  },
  nextAppointment(params) {
    return api().get('/api/contracts/appointments/next', {params});
  },
  lastAppointment(params) {
    return api().get('/api/contracts/appointments/last', {params});
  },

  products(params) {
    return api().get('/api/contracts/products', {params});
  },
  photo(params) {
    return api().get('/api/contracts/photo', {params});
  },
  uploadPhotos(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/contracts/photo', params);
  },
  consents(params) {
    return api().get('/api/contracts/consents', {params});
  },
  getSignature(params) {
    return api().get('/api/contracts/signature', {params});
  },
  addSignature(params) {
    return api().post('/api/contracts/signature', params);
  },
  putOptionsOfContract(params) {
    return api().post('/api/contracts/options/edit', params);
  },

  PDF(params) {
    return api().get('/api/contracts/pdf', {params, responseType: 'blob'});
  },
  proposalPDF(params) {
    return api().get('/api/contracts/proposal-pdf', {params, responseType: 'blob'});
  },
  listPDF(params) {
    return api().get('/api/contracts/list-pdf', {params, responseType: 'blob'});
  },
  labelPDF(params) {
    return api().get('/api/contracts/label-pdf', {params, responseType: 'blob'});
  },
};
