import {createApp, defineAsyncComponent} from 'vue';
import i18n from './extensions/i18n';
import dayjs from './extensions/dayjs';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMultiselect from 'vue-multiselect';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


import VueSweetalert2 from 'vue-sweetalert2';
import clickOutSide from "@mahdikhashan/vue3-click-outside";

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import directives from "./directives/";

import '@/assets/scss/style.scss';
/*
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: false,
  wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
  wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
  wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
  disableStats: true,
  enabledTransports: ['ws', 'wss']
});
*/
require('bootstrap');

import helpers from './helpers'
import Tracker from '@openreplay/tracker';

const app = createApp(App);
store.dispatch('refreshUser')
  .then((user) => {
    try {
      let tracker = null;
      if (process.env.VUE_APP_OPENREPLAY) {
        tracker = new Tracker({
          projectKey: process.env.VUE_APP_OPENREPLAY,
          onStart: ({sessionID}) => console.log("OpenReplay tracker started with session: ", sessionID),
        });
        tracker.start();
        tracker.setUserID(user.email);
        console.log(`OpenReplay tracker started with userId: ${user.email}`)
      }
      app.config.globalProperties.$tracker = tracker;
    } catch (e) {
      console.error(e);
    }
  })
  .finally(() => {
    if (process.env.NODE_ENV === 'development') {
      app.config.performance = true;
    }
    app.use(i18n);
    app.use(router);
    app.use(store);
    app.use(setupCalendar, {});
    app.component('VCalendar', Calendar)
    app.component('VDatePicker', DatePicker)

    app.directive('clickOutSide', clickOutSide)

    app.use(VueAxios, axios);
    app.use(VueSweetalert2, {
      width: 400,
      heightAuto: false,
      reverseButtons: true,
      padding: '2rem',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonColor: null,
      cancelButtonColor: null,
      customClass: {
        actions: 'gap-4',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-transparent',
        denyButton: 'btn btn-light',
      }
    });

    app.component('VueMultiselect', VueMultiselect);

    app.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyBlSwmiSOSj-yth4GlJZYuZdu5Zs729TwE',
        libraries: "places",
        language: 'it',
      },
    })

    app.config.globalProperties.$moment = dayjs;
    app.config.globalProperties.$helpers = helpers;
    //app.config.globalProperties.$googleMapsApiKey = 'AIzaSyBlSwmiSOSj-yth4GlJZYuZdu5Zs729TwE';

    app.component('GoToButton', defineAsyncComponent(() => import('@/components/buttons/GoToButton.vue')));
    app.component('AddButton', defineAsyncComponent(() => import('@/components/buttons/AddButton.vue')));
    app.component('EditButton', defineAsyncComponent(() => import('@/components/buttons/EditButton.vue')));
    app.component('DeleteButton', defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue')));
    app.component('FiltersButton', defineAsyncComponent(() => import('@/components/buttons/FiltersButton.vue')));
    app.component('NextAppointmentButton', defineAsyncComponent(() => import('@/components/buttons/NextAppointmentButton.vue')));
    app.component('MultiOptionsButton', defineAsyncComponent(() => import('@/components/buttons/MultiOptionsButton.vue')));
    app.component('BarcodeReaderButton', defineAsyncComponent(() => import('@/components/buttons/BarcodeReaderButton.vue')));
    app.component('MoneyFormat', defineAsyncComponent(() => import('@/components/MoneyFormat.vue')));
    app.component('CustomInput', defineAsyncComponent(() => import('@/components/inputs/CustomInput.vue')));
    app.component('CustomTextarea', defineAsyncComponent(() => import('@/components/inputs/CustomTextarea.vue')));
    app.component('ClassicInput', defineAsyncComponent(() => import('@/components/inputs/ClassicInput.vue')));
    app.component('DateInput', defineAsyncComponent(() => import('@/components/inputs/DateInput.vue')));
    app.component('RangeInput', defineAsyncComponent(() => import('@/components/inputs/RangeInput.vue')));
    app.component('SearchInput', defineAsyncComponent(() => import('@/components/inputs/SearchInput.vue')));
    app.component('CurrencyInput', defineAsyncComponent(() => import('@/components/inputs/CurrencyInput.vue')));
    app.component('NumericInput', defineAsyncComponent(() => import('@/components/inputs/NumericInput.vue')));
    app.component('QuantityVariantInput', defineAsyncComponent(() => import('@/components/inputs/QuantityVariantInput.vue')));
    app.component('SelectPaymentMethods', defineAsyncComponent(() => import('@/components/inputs/SelectPaymentMethods.vue')));
    app.component('SelectCustom', defineAsyncComponent(() => import('@/components/inputs/Selects/SelectCustom.vue')));
    app.component('SidebarComponent', defineAsyncComponent(() => import('@/components/SidebarComponent.vue')));

    directives(app);

    app.mount('#app');
  })
