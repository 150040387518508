import api from '../api';

export default {
  list(params) {
    return api().get('/api/stores/departments', { params });
  },
  create(params) {
    return api().post('/api/stores/departments/new', params);
  },
  edit(params) {
    return api().post('/api/stores/departments/edit', params);
  },
  sort(params) {
    return api().post('/api/stores/departments/sort', params);
  },
  delete(params) {
    return api().get('/api/stores/departments/delete', { params });
  },
};
