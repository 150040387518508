import { createStore } from 'vuex'

import platform from './platform';
import auth from './auth';
import store from './store';
import cart from './cart';
import chat from './chat';
import contract from './contract';
import product from './product';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    platform,
    auth,
    store,
    cart,
    chat,
    contract,
    product,
  },
});
