import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/payment-methods', {id:'list-payment-methods', signal, params });
  },
  create(params) {
    return api().post('/api/payment-methods/new', params);
  },
  edit(params) {
    return api().post('/api/payment-methods/edit', params);
  },
  sort(params) {
    return api().post('/api/payment-methods/sort', params);
  },
  delete(params) {
    return api().get('/api/payment-methods/delete', { params });
  },
};
