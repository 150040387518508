import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/clients', { signal, params });
  },
  get(params) {
    return api().get('/api/clients/details', { params });
  },
  create(params) {
    return api().post('/api/clients/new', params);
  },
  edit(params) {
    return api().post('/api/clients/edit', params);
  },

  measures(params) {
    const payload = params;
    payload.group = 'measures';
    return api().get('/api/clients/attributes', { params: payload });
  },

  putMeasures(params) {
    return api().post('/api/clients/attributes', params);
  },
};
