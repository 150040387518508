import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/stores/orders', { signal, params });
  },
  statsList(params, signal = null) {
    return api().get('/api/stores/orders/statsList', { signal, params });
  },
  count(params) {
    return api().get('/api/stores/orders/count', { params });
  },
  get(params) {
    return api().get('/api/stores/orders/details', { params });
  },
  create(params) {
    return api().post('/api/stores/orders/new', params);
  },
  createByContract(params) {
    return api().post('/api/stores/orders/new-by-contract', params);
  },
  editDetails(params) {
    return api().post('/api/stores/orders/edit-details', params);
  },
  editProducts(params) {
    return api().post('/api/stores/orders/edit-products', params);
  },
  delete(params) {
    return api().get('/api/stores/orders/delete', { params });
  },
  products(params) {
    return api().get('/api/stores/orders/products', { params });
  },
  PDF(params) {
    return api().get('/api/stores/orders/pdf', { params, responseType: 'blob' });
  },
  listPDF(params) {
    return api().get('/api/stores/orders/list-pdf', { params, responseType: 'blob' });
  },
  send(params) {
    return api().post('/api/stores/orders/send', params);
  },
  importProducts(params) {
    return api().post('/api/stores/orders/import-products', params);
  },
};
