import api from '../api';

export default {
  list(params) {
    return api().get('/api/roles', { params });
  },
  setPermissions(params) {
    return api().post('/api/roles/permissions', params);
  },
};
