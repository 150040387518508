import api from '../api';

export default {

  list(params, signal = null) {
    return api().get('/api/products', {signal, params});
  },
  get(params, signal = null) {
    return api().get('/api/products/get', {signal, params});
  },
  count(params) {
    return api().get('/api/products/count', {params});
  },
  stats(params) {
    return api().get('/api/products/stats', {params});
  },
  import(params) {
    return api().post('/api/products/import', params);
  },
  create(params) {
    return api().post('/api/products/new', params);
  },
  edit(params) {
    return api().post('/api/products/edit', params);
  },
  uploadPhotos(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/products/photos/upload', params);
  },
  delete(params) {
    return api().get('/api/products/delete', {params});
  },
  getVariants(params, signal = null) {
    return api().get('/api/products/variant/list', {signal, params});
  },
  getVariant(params, signal = null) {
    return api().get('/api/products/variant/get', {signal, params});
  },
  addVariant(params) {
    return api().post('/api/products/variant', params);
  },
  getMaxPrice(params) {
    return api().get('/api/products/max-price', {params});
  },

  inventoryPDF(params) {
    return api().get('/api/products/pdf/inventory', {params, responseType: 'blob'});
  },
  labelsPDF(params) {
    return api().get('/api/products/pdf/labels', {params, responseType: 'blob'});
  },
  singleLabelPDF(params) {
    return api().get('/api/products/pdf/single-label', {params, responseType: 'blob'});
  },
};
