import repository from '@/api/repository';

export default {
  namespaced: true,

  state: () => ({
    toRead: 0,
  }),
  getters: {
    toRead: (state) => state.toRead,
  },
  mutations: {
    SET_TO_READ(state, value) {
      state.toRead = value;
    },
  },
  actions: {
    async setToRead({commit}) {
      try {
        await repository.chat.toRead()
          .then(({data}) => {
            commit('SET_TO_READ', data);
          });
      } catch (error) {
        this.error = error;
      }
    },

  },
};
