import api from '../api';

export default {
  list(params, signal = null) {
    return api().get('/api/stores/ddt', { signal, params });
  },
  count(params) {
    return api().get('/api/stores/ddt/count', { params });
  },
  get(params) {
    return api().get('/api/stores/ddt/details', { params });
  },
  create(params) {
    return api().post('/api/stores/ddt/new', params);
  },
  edit(params) {
    return api().post('/api/stores/ddt/edit', params);
  },
  uploadFiles(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/stores/ddt/files/upload', params);
  },
  delete(params) {
    return api().get('/api/stores/ddt/delete', { params });
  },
  products(params) {
    return api().get('/api/stores/ddt/products', { params });
  },
  downloadFiles(params) {
    return api().get('/api/stores/ddt/files', { params, responseType: 'arraybuffer' });
  },
  notInvoiced(params) {
    return api().get('/api/stores/ddt/not-invoiced', { params });
  },

  labelsPDF(params) {
    return api().get('/api/stores/ddt/pdf/labels', { params, responseType: 'blob' });
  },

  labelsPDFByProducts(params) {
    return api().get('/api/stores/ddt/pdf/labels-by-products', { params, responseType: 'blob' });
  },
};
