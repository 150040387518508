export default {
    it: {
        shortFormat: {
            dateStyle: "short"
        },
        longFormat: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    es: {
        shortFormat: {
            dateStyle: "short"
        },
        longFormat: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    en: {
        shortFormat: {
            dateStyle: "short"
        },
        longFormat: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    ru: {
        shortFormat: {
            dateStyle: "short"
        },
        longFormat: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
}