import store from '@/store';

export default (to, from, next) => {
  if (store.getters.authenticated && !store.getters.verified) {
    next();
  } else if (store.getters.authenticated && store.getters.verified) {
    next({ name: 'platform' });
  } else {
    next({ name: 'login' });
  }
};
