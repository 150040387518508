export default {
  state: () => ({
    scrollY: 0,
    windowWidth: null,
    isMobile: false,
    loading: false,
    locale: 'it-IT',
    currency: 'EUR',
    timezone: 'Europe/Rome',
  }),
  getters: {
    isMobileDevice: () => 'ontouchstart' in document.documentElement,
    isMobile: (state) => state.windowWidth < 992,
    isTablet: (state) => state.windowWidth < 1024,
    isLoading: (state) => state.loading,
    locale: (state) => state.locale,
    currency: (state) => state.currency,
    timezone: (state) => state.timezone,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, width) {
      state.windowWidth = width;
      state.isMobile = width < 992;
    },

    UPDATE_WINDOW_SCROLL_Y(state, val) {
      state.scrollY = val;
    },
    UPDATE_LOADING(state, value) {
      state.loading = value;
    },
  },
  actions: {
    async setLoading({ commit }, loading) {
      commit('UPDATE_LOADING', loading);
    },
  },
};
