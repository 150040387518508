import store from '@/store';

export default (to, from, next) => {
  let permissionsToAccess = typeof to.meta.permissions !== 'undefined' ? to.meta.permissions : [];
  if (!Array.isArray(permissionsToAccess)) permissionsToAccess = [permissionsToAccess];
  const hasPermissions = store.getters.hasPermissions(permissionsToAccess);
  if (hasPermissions) {
    next();
  } else {
    next({ name: 'platform' });
  }
};
