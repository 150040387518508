export default {
  namespaced: true,

  state: () => ({
    products: sessionStorage.products ? JSON.parse(sessionStorage.getItem('products')) : [],
    paymentMethod: null,
    discount: null,
  }),
  getters: {
    products: (state) => state.products,
    productsInTheCart: (state) => state.products.length,
    totalCart: (state) => {
      let total = 0;
      state.products.forEach((p) => {
        if (!p) return;
        let {price} = p;
        if (p.discount_type === 'amount') price = p.price - p.discount;
        else price = p.price - (p.price * (p.discount / 100));
        total += price * p.quantity;
      });
      return total;
    },
  },
  mutations: {
    SET_PAYMENT_METHOD(state, value) {
      state.paymentMethod = value;
    },
    SET_DISCOUNT(state, value) {
      state.discount = value;
    },
    ADD_PRODUCT(state, value) {
      state.products.push(value);
      sessionStorage.products = JSON.stringify(state.products);
    },
    REMOVE_PRODUCT(state, value) {
      const index = state.products.findIndex((p) => p.id === value);
      state.products.splice(index, 1);
      sessionStorage.products = JSON.stringify(state.products);
    },
    EDIT_PRODUCT(state, payload) {
      const index = state.products.findIndex((p) => p.id === payload.id);
      if (typeof payload.quantity !== 'undefined') state.products[index]['quantity'] = payload.quantity;
      if (typeof payload.discount !== 'undefined') state.products[index]['discount'] = payload.discount;
      if (typeof payload.discount_type !== 'undefined') state.products[index]['discount_type'] = payload.discount_type;
      sessionStorage.products = JSON.stringify(state.products);
    },
    EMPTY_CART(state) {
      state.products = [];
      sessionStorage.products = JSON.stringify(state.products);
    },
  },
  actions: {
    addProductToCart({commit, state}, payload) {
      const item = {
        id: payload.product.id,
        name: payload.product.name,
        rif: payload.product.rif,
        image: payload.product.image,
        price: payload.product.price,
        features: payload.product.features,
        availability: payload.product.availability,
        quantity: (typeof payload.quantity !== 'undefined') ? payload.quantity : 1,
        discount: (typeof payload.discount !== 'undefined') ? payload.discount : 0,
        discount_type: (typeof payload.discount_type !== 'undefined') ? payload.discount_type : 'percentage',
      };
      const prod = state.products.find((p) => p.id === payload.product.id);
      if (!prod) {
        commit('ADD_PRODUCT', item);
      } else {
        let qnt = prod.quantity + item.quantity;
        if (qnt > payload.product.availability) qnt = payload.product.availability;
        commit('EDIT_PRODUCT', {id: prod.id, quantity: qnt});
      }
    },
    removeProductToCart({commit}, productId) {
      commit('REMOVE_PRODUCT', productId);
    },
    editProductToCart({commit}, payload) {
      const item = {
        id: payload.id,
      };
      if (typeof payload.quantity !== 'undefined') item.quantity = payload.quantity;
      if (typeof payload.discount !== 'undefined') item.discount = payload.discount;
      if (typeof payload.discount_type !== 'undefined') item.discount_type = payload.discount_type;
      commit('EDIT_PRODUCT', item);
    },
    emptyCart({commit}) {
      commit('EMPTY_CART');
    },

  },
};
