import AuthLayout from "./Auth/AuthLayout.vue";
import AuthAsideLayout from "./Auth/AuthAsideLayout.vue";
import BlankLayout from "./BlankLayout.vue";
import PlatformLayout from "./PlatformLayout.vue";
import WizardLayout from "./WizardLayout.vue";

export default {
  BlankLayout,
  AuthLayout,
  AuthAsideLayout,
  PlatformLayout,
  WizardLayout
};
