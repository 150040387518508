import auth from './repositories/auth';
import store from './repositories/store';

import brand from './repositories/brand';
import category from './repositories/category';
import productOption from './repositories/productOption';
import season from './repositories/season';
import supplier from './repositories/supplier';
import tag from './repositories/tag';
import unitType from './repositories/unitType';
import vatType from './repositories/vatType';
import client from './repositories/client';
import product from './repositories/product';
import directOrder from './repositories/directOrder';
import order from './repositories/order';
import ddt from './repositories/ddt';
import paymentMethod from './repositories/paymentMethod';
import employee from './repositories/employee';
import appointment from './repositories/appointment';
import appointmentType from './repositories/appointmentType';
import contract from './repositories/contract';
import intervention from './repositories/intervention';
import search from './repositories/search';
import internalDdt from './repositories/internalDdt';
import invoice from './repositories/invoice';
import importData from './repositories/import';
import role from './repositories/role';
import inventory from './repositories/inventory';
import department from './repositories/department';
import chat from './repositories/chat';
import ceremony from './repositories/ceremony';
import widget from './repositories/widget';
import statistics from './repositories/statistics';

export default {
  auth,
  store,
  brand,
  category,
  productOption,
  season,
  supplier,
  tag,
  unitType,
  vatType,
  client,
  product,
  directOrder,
  order,
  ddt,
  paymentMethod,
  employee,
  appointment,
  appointmentType,
  contract,
  intervention,
  search,
  internalDdt,
  invoice,
  importData,
  role,
  inventory,
  department,
  chat,
  ceremony,
  widget,
  statistics,
};
